<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-spacer />
                        <v-toolbar-title>Change Password</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-form v-model="isFormValid" @submit.prevent="save">
                        <v-card-text class="pb-0 mt-2">
                            <v-text-field
                                v-model="oldPassword"
                                label="Old Password"
                                type="password"
                                required
                                outlined 
                                autofocus />
                            <v-text-field
                                v-model="newPassword"
                                label="New Password"
                                type="password"
                                :rules="[rules.required, rules.min, rules.max]"
                                required
                                outlined />
                            <v-text-field
                                v-model="confirmNewPassword"
                                label="Confirm New Password"
                                type="password"
                                :rules="[rules.required, passwordsMatch]"
                                required
                                outlined />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <div class="flex-grow-1" />
                            <app-button
                                color="quaternary"
                                :disabled="!isFormValid" 
                                type="submit">
                                Change
                            </app-button>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { changePassword } from '@/features/account/services/accountService';

export default {
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            isFormValid: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length >= 8 || "Min 8 characters",
                max: v => v.length <= 50 || "Max 50 characters",
                //upperCaseNumber: value => {
                //    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
                //    return pattern.test(value) || 'Password must contain at least one uppercase
                //    letter, at least one lowercase letter, and at least one number.'
                //},
            },
        };
    },

    computed: {
        passwordsMatch() {
            return this.newPassword === this.confirmNewPassword || "Password must match";
        },
    },

    methods: {
        ...mapActions({
            refreshUser: "authentication/refreshUser"
        }),
        async save() {
            await changePassword(this.oldPassword, this.newPassword);
            await this.refreshUser();
            this.$router.push("/");
        },
    },
};
</script>
